<!-- imagen del inicio-->
<div class="myDiv img-fluid">
    <div class="text-center py-5 px-4">
        <div class="py-5">
            <h1 class="card-title text-uppercase" style="color: white">
                <strong>Contadores y abogados a su servicio</strong>
            </h1>
        </div>
        <div class="py-4">
            <button type="button" class="btn btn-xl btn-outline-light justify-content-center text-uppercase"
                (click)="scrollToElement(menu)">
                VER MAS
            </button>
        </div>
    </div>
</div>
<!-- seccion acerca de nosotros-->
<div class="section bg-dark">
    <div class="row">
        <div class="container text-white">
            <div class="row justify-content-center">
                <div class="col-md-6 align-self-center text-center">
                    <div class="card-body">
                        <h2>ASESORES JTC & ASOCIADOS S.A.S</h2>
                        <p class="text-justify">
                            Es una empresa constituida en diciembre del año 2020, la cual tiene como finalidad asesorar,
                            tramitar y ejecutar labores en las áreas del derecho y la contaduría pública, prestando un
                            portal de servicios a personas naturales y jurídicas según su necesidad.
                        </p>
                        <p class="text-justify">
                            La empresa cuenta con un grupo de trabajo especializado en sus respectivas áreas, los cuales
                            prestan sus servicios de forma eficiente y transparente, garantizando prudencia y
                            confidencialidad en el manejo de la información, de igual forma haciendo buen uso de la
                            misma para la realización de proceso o solicitudes ante terceras personas o entidades.
                        </p>
                        <p class="text-justify">
                            ASESORES JTC & ASOCIADOS S.A.S, como entidad asesora o gestora de procesos brinda
                            acompañamiento a las personas, comerciantes y empresarios en sus ideas de negocios o
                            resolución de conflictos mediante asesorías personalizadas.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 align-self-center">
                    <img src="../../../assets/img/inicio/balanza-nosotros.jpg" class="img-fluid" alt="contabilidad">
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<!-- menu de navegación-->
<div class="container-fluid" #menu>
    <div class="row">
        <div class="col-md-12">
            <div class="row justify-content-center">
                <div class="col-md-4">
                    <div class="card shadow p-3 mb-5 bg-white rounded" [routerLink]="['/juridicos']"
                        routerLinkActive="active" type="button">
                        <img class="card-img-top" src="../../../assets/img/juridico/justicia.jpg" />
                        <div class="card-block">
                            <br>
                            <h2 class="card-title text-center text-uppercase">
                                Servicios Juridicos
                            </h2>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card shadow p-3 mb-5 bg-white rounded" [routerLink]="['/tributarios']"
                        routerLinkActive="active" type="button">
                        <img class="card-img-top" src="../../../assets/img/tributarios/torres-monedas.jpg" />
                        <div class="card-block">
                            <br>
                            <h2 class="card-title text-center text-uppercase">
                                Servicios Tributarios
                            </h2>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card shadow p-3 mb-5 bg-white rounded" [routerLink]="['/contables']"
                        routerLinkActive="active" type="button">
                        <img class="card-img-top" src="../../../assets/img/contable/servicios-contables.jpg" />
                        <div class="card-block">
                            <br>
                            <h2 class="card-title text-center text-uppercase">
                                Servicios Contables
                            </h2>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card shadow p-3 mb-5 bg-white rounded" [routerLink]="['/declaraciones']"
                        routerLinkActive="active" type="button">
                        <img class="card-img-top" src="../../../assets/img/declaraciones/contabilidad.jpg" />
                        <div class="card-block">
                            <br>
                            <h2 class="card-title text-center text-uppercase">
                                Declaraciones
                            </h2>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card shadow p-3 mb-5 bg-white rounded" [routerLink]="['/impuestos']"
                        routerLinkActive="active" type="button">
                        <img class="card-img-top" src="../../../assets/img/impuestos/impuestos.jpg" />
                        <div class="card-block">
                            <br>
                            <h2 class="card-title text-center text-uppercase">
                                Impuestos
                            </h2>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card shadow p-3 mb-5 bg-white rounded" [routerLink]="['/ugpp']"
                        routerLinkActive="active" type="button">
                        <img class="card-img-top" src="../../../assets/img/ugpp/ugpp-noticias.jpg" />
                        <div class="card-block">
                            <br>
                            <h2 class="card-title text-center text-uppercase">
                                UGPP
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- modal inicio -->
<div id="modalInicio" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content bg-dark">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                <div class="container text-center myImg">
                    <div class="text-center py-5 px-4 justify-content-center">
                        <div class="py-5 align-self-center text-center">
                            <h3 class="card-title h1 my-4 py-2" style="color: white">
                                <strong> ¿ Quieres saber las nuevas noticias acerca de... ?</strong>
                            </h3>
                            <button type="button" class="btn btn-xl btn-outline-light text-uppercase"
                                [routerLink]="['/noticias']" data-dismiss="modal">
                                Saber Más
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>