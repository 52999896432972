import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './components/core/page-not-found/page-not-found.component';
import { DeclaracionesComponent } from './components/declaraciones/declaraciones.component';
import { ImpuestosComponent } from './components/impuestos/impuestos.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { JuridicoComponent } from './components/juridico/juridico.component';
import { NoticiasComponent } from './components/noticias/noticias.component';
import { ContableComponent } from './components/contable/contable.component';
import { TributarioComponent } from './components/tributario/tributario.component';
import { UgppComponent } from './components/ugpp/ugpp.component';

const routes: Routes = [
  { path: 'noticias', component: NoticiasComponent },
  { path: 'declaraciones', component: DeclaracionesComponent },
  { path: 'impuestos', component: ImpuestosComponent },
  { path: 'juridicos', component: JuridicoComponent },
  { path: 'contables', component: ContableComponent },
  { path: 'ugpp', component: UgppComponent },
  { path: 'tributarios', component: TributarioComponent },
  { path: 'inicio', component: InicioComponent },
  { path: '', redirectTo: 'inicio', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
