import { Component } from '@angular/core';

@Component({
  selector: 'app-declaraciones',
  templateUrl: './declaraciones.component.html',
  styleUrls: []
})
export class DeclaracionesComponent {

  primerTitulo = 'DECLARACION DE RENTA Y COMPLEMENTARIOS';
  primerParrafo = 'ASESORES JTC & ASOCIADOS S.A.S brinda asistencia en materia de declaraciones de renta a las personas naturales y jurídicas que tienen la obligatoriedad y responsabilidad legal de presentar cada año dicha declaración, de igual forma aquellas personas naturales no siendo responsables de esta obligación superen los límites establecidos por la Dirección de Impuestos y Aduanas Nacionales – DIAN, tendrán que cumplir con la obligación de presentar para evitar sanciones a futuro.';
  segundoTitulo = 'DECLARACIÓN DE INDUSTRIA Y COMERCIO AVISOS Y TABLEROS';
  segundoParrafo = 'Asesoramos a las personas jurídicas y naturales respecto a la obligación que se establece con el municipio mediante la inscripción, declaración y pago de los tributos;  Este es un impuesto que debe declararse y pagarse por aquellas personas naturales, jurídicas y sociedades de hecho que realicen directa o indirectamente dentro del territorio cualquier actividad industrial, comercial o de servicios; adicional a este se tiene el impuesto complementario de Avisos y Tableros el cual es un tributo que se paga por la instalación de avisos en el espacio público y se declara en conjunto con la declaración de Industria y Comercio. ';
  tercerTitulo = 'DECLARACIÓN ANUAL DEL RÉGIMEN SIMPLE DE TRIBUTACIÓN';
  tercerParrafo = 'Es un nuevo sistema o régimen tributario que simplifica de alguna manera el cumplimiento de las obligaciones tributarias y trae consigo varias ventajas frente al régimen ordinario de renta. Asesores JTC & Asociados S.A.S te brinda acompañamiento en el proceso de presentación de anticipos bimestrales, al igual que en la declaración anual de este régimen simple de tributación y una planeación en materia tributaria.';

}
