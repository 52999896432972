<div class="section-info">
    <div class="row">
        <div class="container text-black">
            <div class="row justify-content-center">
                <div class="col-md-6 align-self-center text-center">
                    <div class="card-body">
                        <h2>Noticias relevantes</h2>
                        <p class="text-justify">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium magni, asperiores
                            quo itaque eaque delectus illum ab modi ratione corrupti eligendi nesciunt a culpa
                            repudiandae placeat facilis adipisci harum aperiam.
                        </p>
                        <br>
                        <p class="text-justify">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium magni, asperiores
                            quo itaque eaque delectus illum ab modi ratione corrupti eligendi nesciunt a culpa
                            repudiandae placeat facilis adipisci harum aperiam.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <img src="../../../assets/img/noticias/noticias.jpg" class="img-fluid" alt="contabilidad">
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="container text-black">
            <div class="row justify-content-center">
                <div class="col-md-12 align-self-center text-center">
                    <div class="card-body">
                        <p class="text-justify">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium magni, asperiores
                            quo itaque eaque delectus illum ab modi ratione corrupti eligendi nesciunt a culpa
                            repudiandae placeat facilis adipisci harum aperiam.
                        </p>
                        <br>
                        <p class="text-justify">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium magni, asperiores
                            quo itaque eaque delectus illum ab modi ratione corrupti eligendi nesciunt a culpa
                            repudiandae placeat facilis adipisci harum aperiam.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>