<br>
<section>
    <div class="container">
        <app-text-img titulo={{primerTitulo}} parrafo={{primerParrafo}}
            imagen='../../../../assets/img/juridico/arbol-juridico.jpg'>
        </app-text-img>
        <br>
        <div class="row">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 align-self-center">
                        <img src="../../../../assets/img/juridico/apreton-de-manos.jpg" class="img-fluid"
                            alt="contabilidad">
                    </div>
                    <div class="col-md-6 align-self-center">
                        <div class="card shadow">
                            <div class="card-body">
                                <h2 class="text-center">ARBITRAJE</h2>
                                <p class="text-justify">
                                    El arbitraje es un mecanismo alternativo mediante el cual las partes establecen
                                    soluciones a los
                                    conflictos y controversias sobre asuntos de libre disposición o sobre aquellos
                                    mediante el cual
                                    la ley autorice. Estos árbitros pueden ser nombrados conjuntamente por las partes, o
                                    las mismas
                                    pueden delegar a un tercero o centro de arbitraje. La empresa ASESORES JTC &
                                    ASOCIADOS S.A.S,
                                    presta sus servicios de mediación o resolución de conflictos en:
                                </p>
                                <ul>
                                    <li>Proceso mercantil o comercial</li>
                                    <li>Representación en audiencia de conciliación</li>
                                    <li>Proceso laboral</li>
                                    <li>Proceso civil</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-6 align-self-center text-center">
                        <div class="card shadow">
                            <div class="card-body">
                                <h2>DERECHO LABORAL </h2>
                                <p class="text-justify">
                                    Brindamos asesoramiento a las personas naturales y jurídicas respecto a las formas
                                    de contratación
                                    y a las obligaciones legales que se derivan del mismo, debido a que este derecho se
                                    encarga de
                                    regular las relaciones que se establecen a raíz del trabajo humano entre
                                    trabajadores y empleadores
                                    intervinientes en una relación laboral, en la cual se le deben reconocer al empleado
                                    todas sus
                                    prestaciones y seguridad social.
                                </p>
                                <p class="text-justify">
                                    Mediante esta asesoría se busca que el empleador se concientice de la obligación
                                    contractual que adquiere
                                    con el empleado y las consecuencias económicas que pueden surgir de una mala
                                    decisión. Mientras que en
                                    los empleados se brinda asesoría respecto de las posibles vulneraciones a sus
                                    derechos, verbi gratia,
                                    cuando no se les reconocen las prestaciones sociales o son despedidos sin que medie
                                    justa causa para
                                    el reconocimiento de indemnizaciones.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 align-self-center">
                        <img src="../../../../assets/img/juridico/contratos.jpg" class="img-fluid" alt="contabilidad">
                    </div>
                </div>
            </div>
        </div>
        <br>
        <app-img-text titulo={{segundoTitulo}} parrafo={{segundoParrafo}}
            imagen='../../../../assets/img/juridico/codigo-civil.jpg'>
        </app-img-text>
        <br>
        <app-text-img titulo={{tercerTitulo}} parrafo={{tercerParrafo}}
            imagen='../../../../assets/img/juridico/derecho-comercial.jpg'>
        </app-text-img>

    </div>
</section>
<br>