import { Component } from '@angular/core';

@Component({
  selector: 'app-ugpp',
  templateUrl: './ugpp.component.html',
  styleUrls: []
})
export class UgppComponent {

  primerTitulo = 'Parafiscales';
  primerParrafo = 'Son contribuciones obligatorias por parte de los empleadores a las cajas de compensación familiar, al Instituto Colombiano de Bienestar Familiar y al Servicio Nacional de Aprendizaje; con un monto correspondiente al 9 % del costo mensual de la nómina.';
  segundoTitulo = 'Pensiones';
  segundoParrafo = 'Es una prestación económica con el proposito de proteger al trabajador si le sucediera un posible accidente de trabajo, padecer una enfermedad, sufrir un accidente no laboral, o al cumplir la edad de retiro estipulada';

}
