<footer class="footer text-center bg-dark">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 mb-5 mb-lg-0">
                <h4 class="text-uppercase mb-4">Envianos un correo</h4>
                <h4 class="mb-2">asesoresjtc@gmail.com</h4>
            </div>
            <div class="col-lg-4 mb-5 mb-lg-0">
                <h4 class="text-uppercase mb-4">Encuentranos En</h4>
                <a class="btn btn-outline-light btn-social mx-1"
                    href="https://www.facebook.com/Asesores-JTC-Asociados-SAS-106658434622215/" target="_blank">
                    <i class="fab fa-fw fa-facebook-f"></i></a>
                <a class="btn btn-outline-light btn-social mx-1" href="https://twitter.com" target="_blank">
                    <i class="fab fa-fw fa-twitter"></i></a>
                <a class="btn btn-outline-light btn-social mx-1" href="https://www.instagram.com" target="_blank">
                    <i class="fab fa-fw fa-instagram"></i></a>
                <!--<a class="btn btn-outline-light btn-social mx-1" href="https://www.youtube.com" target="_blank">
                    <i class="fab fa-fw fa-youtube"></i></a> -->
            </div>
            <div class="col-lg-4 mb-5 mb-lg-0">
                <h4 class="text-uppercase mb-4">LLamanos</h4>
                <h5 class="mb-2"> 321 4327463 </h5>
                <h5 class="mb-2"> 322 6346139 </h5>
            </div>
        </div>
    </div>
    <br>
    <div class="text-uppercase text-center py-3">© Copyright {{year}} ASESORES JTC & ASOCIADOS S.A.S
    </div>
</footer>