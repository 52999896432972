<br>
<section>
    <div class="container">
        <div class="row">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-6 align-self-center text-center">
                        <div class="card shadow">
                            <div class="card-body">
                                <h2>UNIDAD DE GESTIÓN PENSIONAL Y PARAFISCALES (UGPP)</h2>
                                <p class="text-justify">
                                    La UGPP es una entidad que controla las obligaciones de afiliación y pago que
                                    realizan
                                    las empresas y personas naturales dependientes e independientes al Sistema de
                                    Seguridad Social;
                                    facultada para exigir pago de aportes que no fueron remunerados, pago de aportes
                                    incompletos o
                                    imposición de sanciones por la omisión de la afiliación al Sistema de Seguridad
                                    Social.
                                </p>
                                <p class="text-justify">
                                    ASESORES JTC & ASOCIADOS S.A.S tiene como finalidad la correcta defensa de sus
                                    intereses frente a
                                    las diferentes solicitudes de la entidad como lo pueden ser: Invitaciones para
                                    declarar o corregir,
                                    requerimientos para declarar o corregir, pliegos de cargos, liquidaciones oficiales,
                                    entre otra documentación o
                                    información solicitada por la entidad.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 align-self-center">
                        <img src="../../../assets/img/ugpp/ugpp-noticias.jpg" class="img-fluid" alt="contabilidad">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<br>