import { Component } from '@angular/core';

@Component({
  selector: 'app-servicios',
  templateUrl: './contable.component.html',
  styleUrls: []
})
export class ContableComponent {

  primerTitulo = 'SERVICIOS CONTABLES ';
  primerParrafo = 'ASESORES JTC & ASOCIADOS S.A.S es una empresa que ofrece a los contribuyentes los servicios de contabilidad, teneduría de libros, elaboración de informes y reportes contables y financieros para el uso, análisis y toma de decisiones de sus propietarios o accionistas, al igual aquellos exigidos por terceras personas o entidades y los órganos de control; de igual forma se garantiza el cumplimiento de los deberes formales y sustanciales que la ley exige.';
  segundoTitulo = 'NORMAS INTERNACIONALES DE INFORMACION FINANCIERA - NIIF';
  segundoParrafo = 'Trabajamos para que tu empresa funcione en el marco normativo de estándares internacionales adoptados en Colombia mediante la ley 1314 de 2009, la cual busca transparencia en las transacciones y hechos económico realizados por una entidad. Te prestamos los servicios en el diseño del manual de políticas de tu empresa para que clarifiques cada uno de los procedimientos que se realicen y la forma de reconocimiento, medición, presentación e información a revelar.';
  tercerTitulo = 'GESTION EMPRESARIAL ';
  tercerParrafo = 'Asesoramiento a las empresas mediante medidas estratégicas que garanticen una mejorar productividad y competitividad en los diferentes mercados, ejerciendo un buen manejo administrativo y controlando de forma eficiente el uso de los recursos.';

}
