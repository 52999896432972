import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-img-text',
  templateUrl: './img-text.component.html',
  styleUrls: []
})
export class ImgTextComponent {

  @Input() titulo: string;
  @Input() parrafo: string;
  @Input() imagen: string;

  constructor() { }

}
