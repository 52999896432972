<br>
<section>
    <div class="container">
        <app-text-img titulo={{primerTitulo}} parrafo={{primerParrafo}}
            imagen='../../../../assets/img/tributarios/tributos-casa.jpg'>
        </app-text-img>
        <br>
        <app-img-text titulo={{segundoTitulo}} parrafo={{segundoParrafo}}
            imagen='../../../../assets/img/tributarios/reunion-sala.jpg'>
        </app-img-text>
    </div>
</section>
<br>