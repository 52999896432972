<header class="bg-light">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-md-6">
        <a class="navbar-brand" [routerLink]="['/inicio']">
          <img src="../../../../assets/img/inicio/logo.png" width="200" alt="Logo pagina" id="logo-main">
        </a>
      </div>
      <div class="col-md-6 align-content-center">
        <div class="row align-items-center">
          <div class="col-sm-4 align-content-center">
            <h6 class="text-uppercase">Encuentranos En</h6>
            <a class="btn btn-outline-dark btn-social-white mx-1"
              href="https://www.facebook.com/Asesores-JTC-Asociados-SAS-106658434622215/" target="_blank">
              <i class="fab fa-fw fa-facebook-f"></i></a>
            <a class="btn btn-outline-dark btn-social-white mx-1" href="https://twitter.com" target="_blank">
              <i class="fab fa-fw fa-twitter"></i></a>
            <a class="btn btn-outline-dark btn-social-white mx-1" href="https://www.instagram.com" target="_blank">
              <i class="fab fa-fw fa-instagram"></i></a>
          </div>
          <div class="col-sm-4 align-content-center">
            <h6 class="text-uppercase">Envianos un correo</h6>
            <h6 class="text-black-50">asesoresjtc@gmail.com</h6>
          </div>
          <div class="col-sm-4 align-content-center">
            <h6 class="text-uppercase">LLamanos</h6>
            <h6 class="text-black-50">321 4327463</h6>
            <h6 class="text-black-50">322 6346139</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark static-top text-uppercase no-padding">
      <button class="navbar-toggler align-content-center" type="button" data-toggle="collapse"
        data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link text-white" [routerLink]="['/inicio']">Inicio</a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link text-white" [routerLink]="['/juridicos']">Servicios juridicos</a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link text-white" [routerLink]="['/tributarios']">Servicios Tributarios</a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link text-white" [routerLink]="['/contables']">Servicios Contables</a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link text-white" [routerLink]="['/declaraciones']">Declaraciones</a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link text-white" [routerLink]="['/impuestos']">Impuestos</a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link text-white" [routerLink]="['/ugpp']">UGPP</a>
          </li>
        </ul>
      </div>
  </nav>
</header>