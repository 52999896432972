import { Component } from '@angular/core';

@Component({
  selector: 'app-juridico',
  templateUrl: './juridico.component.html',
  styleUrls: []
})
export class JuridicoComponent {

  primerTitulo = 'DERECHO SOCIETARIO';
  primerParrafo = 'Ofrece asesoramiento en los tramites de constitución de sociedades mercantiles, al igual que el proceso de disolución y liquidación de las mismas. Redacción de contratos, estatutos sociales, reformas estatutarias, patente comercial, implementación jurídica de acuerdos de compra y venta de compañías, acuerdos de accionistas entre otros. Asistencia a concejo de administración, juntas de obligacionistas y juntas de accionistas';
  segundoTitulo = 'DERECHO CIVIL';
  segundoParrafo = ' Nuestra empresa te brinda apoyo profesional en todo lo relacionado al derecho civil respecto a responsabilidad contractual o extracontractual, especialmente en todos aquellos eventos en los que surgen afectaciones directas e indirectas por causa de un mal procedimiento, un accidente de tránsito, indemnizaciones por perjuicios, procesos de sucesión; además te brindamos asesoría respecto de la compra y venta de bienes muebles e inmuebles, legalización de predios entre otros.';
  tercerTitulo = 'DERECHO COMERCIAL';
  tercerParrafo = 'Asesoramos a las empresas y personas naturales en las relaciones con fines comerciales y de intercambio económico que surgen de vínculos entre dos o más partes, de igual forma todos aquellos actos de comercio legalmente calificados y de las relaciones jurídicas derivadas del mismo.';

}
