import { Component } from '@angular/core';

@Component({
  selector: 'app-tributario',
  templateUrl: './tributario.component.html',
  styleUrls: []
})
export class TributarioComponent {

  primerTitulo = 'DERECHO TRIBUTARIO ';
  primerParrafo = 'Con nuestros grupo profesional especializado en esta área ofrecemos asesorías personalizada en el manejo fiscal de las cargas tributarias impuestas por la Dirección de Impuestos y Aduanas Nacionales – DIAN para el recaudo de los tributos declarado por personas naturales o jurídicas responsables de presentar y pagar; igualmente el marco normativo en materia fiscal para su adopción y aplicación en los procesos empresariales, y las actualizaciones que surjan por expedición de otras leyes, decretos, resoluciones, comunicado entre otros. ';
  segundoTitulo = 'CONTENCIOSO - ADMINISTRATIVO';
  segundoParrafo = 'Nuestro grupo de profesionales en materia tributaria ofrece servicios de acompañamiento a todas las personas naturales y jurídicas que se encuentra inmersas en proceso fiscales con la administración, actuando en defensa del contribuyente cuando la administración tributaria ejerza imputación sobre ciertos hechos o eventos que pueden generar sanciones de cualquier índole, perjudicando la situación económica de la empresa y su funcionalidad en el tiempo.';
}
