import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/core/footer/footer.component';
import { NavbarComponent } from './components/core/navbar/navbar.component';
import { PageNotFoundComponent } from './components/core/page-not-found/page-not-found.component';
import { DeclaracionesComponent } from './components/declaraciones/declaraciones.component';
import { ImpuestosComponent } from './components/impuestos/impuestos.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { JuridicoComponent } from './components/juridico/juridico.component';
import { NoticiasComponent } from './components/noticias/noticias.component';
import { ImgTextComponent } from './components/reutilizables/img-text/img-text.component';
import { TextImgComponent } from './components/reutilizables/text-img/text-img.component';
import { ContableComponent } from './components/contable/contable.component';
import { TributarioComponent } from './components/tributario/tributario.component';
import { UgppComponent } from './components/ugpp/ugpp.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    TributarioComponent,
    FooterComponent,
    InicioComponent,
    PageNotFoundComponent,
    TextImgComponent,
    ImgTextComponent,
    DeclaracionesComponent,
    ImpuestosComponent,
    JuridicoComponent,
    UgppComponent,
    ContableComponent,
    NoticiasComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
