<br>
<section>
    <div class="container">
        <app-text-img titulo={{primerTitulo}} parrafo={{primerParrafo}}
            imagen='../../../../assets/img/contable/secretaria-contable.jpg'>
        </app-text-img>
        <br>
        <app-img-text titulo={{segundoTitulo}} parrafo={{segundoParrafo}}
            imagen='../../../../assets/img/contable/mundo-digital.jpg'>
        </app-img-text>
        <br>
        <app-text-img titulo={{tercerTitulo}} parrafo={{tercerParrafo}}
            imagen='../../../../assets/img/contable/oficina-reunion.jpg'>
        </app-text-img>
    </div>
</section>
<br>