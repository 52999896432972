<div class="container text-center myImg">
    <div class="text-center py-5 px-4 justify-content-center">
        <div class="py-5 align-self-center text-center">
            <br>
            <br>
            <h1 class="card-title h1 my-4 py-2 text-uppercase" style="color: black">
                Error al buscar la pagina
            </h1>
            <button type="button" class="btn btn-xl btn-outline-light text-uppercase" [routerLink]="['/']">
                Volver al Inicio
            </button>
        </div>
    </div>
</div>