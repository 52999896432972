import { Component } from '@angular/core';

@Component({
  selector: 'app-impuestos',
  templateUrl: './impuestos.component.html',
  styleUrls: []
})
export class ImpuestosComponent {

  primerTitulo = 'IMPUESTOS';
  primerParrafo = 'El régimen impositivo colombiano se divide en tres niveles de recaudo nacional, departamental y municipal; Asesores JTC & Asociados S.A.S incluye la asistencia, elaboración, revisión y aprobación de todos tus impuestos y las Declaraciones Tributarias a los distintos entes de control. ';

}
