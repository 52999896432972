import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  constructor() { }
  ngOnInit(): void {
    $(() => {
      ($('#modalInicio') as any).modal('toggle');
    });
  }

  scrollToElement($element: any): void {
    $element.scrollIntoView({ behavior: 'smooth' });
  }

}
