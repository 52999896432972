<div class="row">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 align-self-center text-center">
                <div class="card shadow">
                    <div class="card-body">
                        <h2>{{titulo}}</h2>
                        <p class="text-justify">
                            {{parrafo}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 align-self-center ">
                <img [src]="imagen" class="img-fluid" alt="contabilidad">
            </div>
        </div>
    </div>
</div>